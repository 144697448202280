<template>
  <!--风控弹窗-->
  <van-dialog v-model:show="myVisible" @confirm="confirmActive">
    <div class="p-3 pre-wrap fs-14">
      <ul class="notice-box mt-1">
        <li>
          考虑到顾客体感和风控政策，请大家保持合理的采集跟发的频率，勿一次性多选内容一起采集：采集跟发频率控制在1分钟20条信息以内，单品牌发送时间间隔5-10分钟为佳，如若超出则可能影响播货
        </li>
        <li>请大家合理进行跟发，如遇到延迟，漏图等情况，可尝试等待10-30分钟后再次进行尝试</li>
      </ul>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "TheRiskManagement"
}
</script>
<script setup>
import {computed} from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  todayDate: {
    type: String,
    default: ''
  }
})
const emits = defineEmits(['update:modelValue'])

const myVisible = computed({
  set(val) {
    emits('update:modelValue', val)
  },
  get() {
    return props.modelValue
  }
})
// 活动确认
const confirmActive = () => {
  localStorage.setItem('akc:frequencyTime', props.todayDate)
  myVisible.value = false
}
</script>
<style lang="scss" scoped>
.notice-box {
  li {
    list-style: decimal inside;
  }
}

</style>
